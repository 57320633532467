.n-notifications-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: 46rem;
    width: 552px;
    padding: 2rem 2rem 2rem 2rem;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #454b54;
    border-radius: 0.8rem;
    animation: popup 0.5s ease-in-out 1;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    z-index: 800;
}

@keyframes popup {
    0% {
        transform: scale(0.8);
    }
    85% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.n-notif-select {
    min-width: 2rem;
    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
    border: 1px solid #454b54;
    border-radius: 0.4rem;
    margin-right: 0.8125rem;
    font-size: 0.8125rem;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.n-notif-select:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
    transition: background-color 0.3s ease-in-out;
}

.n-notif-select-active {
    min-width: 2rem;
    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
    background-color: #27AE85;
    border: 1px solid #1a8d7e;
    color: white;
    border-radius: 0.4rem;
    margin-right: 0.8125rem;
    font-size: 0.8125rem;
    transition: background-color 0.3s ease-in-out;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.n-notif-select-active:hover {
    cursor: pointer;
}

.notif-bar {
    width: 100%;
    height: 1px;
    background-color: rgb(206, 206, 206);
    z-index: 999;
}

.n-notification-box {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: left;
    transition: background-color 0.3s ease-in-out;
    border-radius: 0.8rem;
    position: relative;
}

.n-notification-box:hover {
    cursor: pointer;
    background-color: rgb(247, 247, 247);
    transition: background-color 0.3s ease-in-out;
}

.n-new-circle2 {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #27AE85;
    border: 1px solid #188870;
    border-radius: 100%;
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    animation: notif-circle 6s ease-in-out infinite;
}

.n-new-circle {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #27AE85;
    border: 1px solid #188870;
    border-radius: 100%;
    position: absolute;
    top: 0.7rem;
    left: 0.2rem;
    animation: notif-circle 6s ease-in-out infinite;
}

@keyframes notif-circle {
    0% {
        transform: scale(1);
    }
    4% {
        transform: scale(0.7);
    }
    8% {
        transform: scale(1.1);
    }
    10% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

.n-notif-message {
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 0.8rem;
    width: 24rem;
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;
    box-sizing: border-box;
    margin-left: 1rem;
    font-size: 0.8125rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.n-notif-message-button {
    color: white;
    font-weight: 500;
    background-color: #27AE85;
    width: 12rem;
    height: 2.4rem;
    border: none;
    font-family: "Inter";
    border-radius: 0.4rem;
    margin-top: 1.2rem;
    transition: background-color 0.3s ease-in-out;
    font-size: 0.8125rem;
}

.n-notif-message-button:hover {
    cursor: pointer;
    background-color: #1e967e;
    transition: background-color 0.3s ease-in-out;
}

.n-notif-message-button-claimed {
    color: #454b54;
    font-weight: 500;
    background-color: rgb(241, 241, 241);
    width: 12rem;
    height: 2.4rem;
    border: none;
    font-family: "Inter";
    border-radius: 0.4rem;
    margin-top: 1.2rem;
    transition: background-color 0.3s ease-in-out;
    font-size: 0.8125rem; 
    cursor: not-allowed;
}

.n-notif-body::-webkit-scrollbar {
    width: 0.4rem;
  }

  .n-notif-body::-webkit-scrollbar-track {
    background: #ececec; /* Background of the scrollbar track */
    border-radius: 10px;
}

.n-notif-body::-webkit-scrollbar-thumb {
    background: #27AE85; /* Color of the scrollbar thumb */
    border-radius: 10px;
}

.n-notif-body::-webkit-scrollbar-thumb:hover {
    background: #23a795; /* Darker thumb on hover */
}

.n-gift-container {
    border: 1px solid #7b8088;
    border-radius: 0.8rem;
    padding: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

.n-gift-container:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
    transition: background-color 0.3s ease-in-out;
}

.n-gift-container-claimed {
    border: 1px solid #7b8088;
    border-radius: 0.8rem;
    padding: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(241, 241, 241);
    filter: grayscale(0.4);
    cursor: not-allowed;
}

.n-gift-status {
    position: absolute;
    left: 160%;
    background-color: #27AE85;
    border-radius: 0.4rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    animation: claim-gift-anim 2s ease-in-out infinite;
}

.n-gift-status-claimed {
    position: absolute;
    left: 160%;
    background-color: #e2e2e2;
    border-radius: 0.4rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #454b54;
    z-index: 99;
}

@keyframes claim-gift-anim {
    0% {
        transform: translateX(0rem);
    }
    50% {
        transform: translateX(-0.7rem);
    }
    100% {
        transform: translateX(0rem);
    }
}

.n-gift-arrow {
    position: absolute;
    left: -0.69rem;
    bottom: 0;
    top: 0.29rem;
    height: 1.4rem;
    width: 1.4rem;
    transform: rotate(45deg);
    background-color: #27AE85;
    z-index: 98;
}

.exit-notif {
    padding: 0.2rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exit-notif:hover {
    cursor: pointer;
    background-color: rgb(243, 243, 243);
    transition: background-color 0.3s ease-in-out;
}

.n-notif-dots {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.3rem 0.4rem 0.3rem 0.4rem;
    transition: background-color 0.3s ease-in-out;
    border-radius: 0.4rem;
}

.n-notif-dots:hover {
    cursor: pointer;
    background-color: rgb(224, 224, 224);
    transition: background-color 0.3s ease-in-out;
}

.n-notif-chart-item {
    position: absolute;
    top: 120%;
    right: -0.4rem;
    border-radius: 0.4rem;
    background-color: white;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    width: max-content;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 2px 4px 6px rgb(224, 224, 224);
    transition: background-color 0.3s ease-in-out;
}

.n-notif-chart-item:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    transition: background-color 0.3s ease-in-out;
}