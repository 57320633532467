.n-profile-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 47.5rem;
    width: 32rem;
    background-color: rgb(252, 252, 252);
    border-radius: 0.8rem;
    animation: popup 0.5s ease-in-out 1;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 800;
}

.n-profile-container::-webkit-scrollbar {
    display: none;
  }

@keyframes popup {
    0% {
        transform: scale(0.8);
    }
    85% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.n-profile-bg {
    height: 11rem;
    width: 100%;
    background-color: #FF9B9B;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    position: relative;
}

.n-profile-circle-0 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    border: 2px solid #FF8989;
    position: absolute;
    top: 53%;
    left: 8%;
}

.n-profile-circle-inner-0 {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background-color: #fff6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.n-profile-status-0 {
    height: 1.2rem;
    width: 1.2rem;
    border: 4px solid white;
    background-color: #27AE85;
    border-radius: 100%;
    position: absolute;
    right: 0.7rem;
    bottom: -0.1rem;
}

.n-profile-like-btn {
    border: 1px solid #E0DFDF;
    background-color: white;
    padding: 0.8rem 1.8rem 0.8rem 1.8rem;
    width: min-content;
    border-radius: 2rem;
    position: absolute;
    top: 85%;
    right: 8%;
    font-family: "Inter";
    font-weight: 600;
    color: #52637D;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.n-profile-like-btn:hover {
    cursor: pointer;
    background-color: #f5f5f7;
    transition: background-color 0.3s ease-in-out;
}

.n-profile-center-cont {
    padding: 2rem 8% 2rem 8%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.n-profile-tags-reel {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 2rem;
}

.n-profile-tag {
    border: 1px solid #9BA4B6;
    border-radius: 0.4rem;
    padding: 0rem 0.7rem 0rem 0.7rem;
    height: 1.6rem;
    font-size: 0.8125rem;
    font-weight: 500;
    margin-right: 0.4rem;
    color: #52637D;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.n-profile-tag:hover {
    cursor: pointer;
    background-color: #f5f5f7;
    transition: background-color 0.3s ease-in-out;
}

.biography-container {
    border: 1px solid #D9D9D9;
    border-radius: 0.8rem;
    width: 100%;
    margin-top: 3rem;
    padding: 1.2rem 1.4rem 2rem 1.4rem;
    box-sizing: border-box;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgb(192, 192, 192);
    position: relative;
    z-index: 997;
}

.bio-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines before cutting off */
  color: #9BA4B6;
  font-size: 0.8125rem;
  margin-top: 1rem;
  font-weight: 400;
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 997;
}

.bio-text-extended {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #9BA4B6;
    font-size: 0.8125rem;
    margin-top: 1rem;
    font-weight: 400;
    height: max-content;
    z-index: 997;
  }

.bio-ribbon-cont {
    position: absolute;
    right: -0.95rem;
    top: 1rem;
    z-index: 997;
    transform: scale(0.7);
}

.bio-rect {
    background-color: #27AE85;
    height: 2.1rem;
    width: 2.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    position: relative;
    z-index: 99;
}

.bio-tri-top {
    width: 0;
    height: 0;
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.2rem solid #27AE85;
    transform: rotate(90deg);
    position: absolute;
    top: -0.58rem;
    left: -0.6rem;
}

.bio-tri-bot {
    width: 0;
    height: 0;
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.2rem solid #27AE85;
    transform: rotate(270deg);
    position: absolute;
    bottom: -0.6rem;
    left: -0.6rem;
}

.ribbon-shadow {
    position: absolute;
    margin-top: -96%;
    background-color: #17A37C;
    height: 2rem;
    width: 0.76rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    z-index: 42;
    right: 0;
}

.more-bio-arrow {
    border: 1px solid #D9D9D9;
    border-radius: 100%;
    background-color: #FAFAFA;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    bottom: -1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0px 4px 6px rgb(192, 192, 192);
}

.more-bio-arrow:hover {
    cursor: pointer;
    background-color: #ebebeb;
    transition: background-color 0.3s ease-in-out;
}

.profile-recommendations-container {
    padding-bottom: 2rem;
}

.profile-book-showcase-cont {
    border: 1px solid #B8C0CC;
    border-radius: 0.8rem;
    height: 7rem;
    margin-top: 2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    position: relative;
}

.profile-rec-heart {
    position: absolute;
    right: 4rem;
}

.n-heart-xd:hover {
    cursor: pointer;
}

.exit-notif-0 {
    padding: 0.2rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exit-notif-0:hover {
    cursor: pointer;
    background-color: #e78080;
    transition: background-color 0.3s ease-in-out;
}